import React from "react"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import Layout from "../../components/layout"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import TextImageJamStack from "../../components/ServiceSinglePage/TextImage/TextImageJamStack"
import ServiceCTA from "./../../components/ServiceLanding/CTA/ServiceCTA"
import HeroJamstack from "./../../components/ServiceSinglePage/Hero/Jamstack/HeroJamstack"
import { SEO } from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"
import localisationData from '../../data/localisation.json'
import ThreeColumnContent from "../../components/ThreeColumnContent"

const heroTextTitle = "Jamstack - The future of the web"
const heroTextSmallText =
    "A modern web development architecture based on client-side JavaScript, reusable APIs, and prebuilt Markup. There is pretty much nothing you can’t achieve with this tech stack."
const ctaTitle = "From buzzwords to deploy ready"
const heroData = [
    "So what is it all about?",
    "A modern web development architecture based on client-side JavaScript, reusable APIs, and prebuilt Markup. There is pretty much nothing you can’t achieve with this tech stack.",
    "Our team loves developing on the Jamstack, it allows us to use tools and technology that we enjoy. For you, it means faster, more secure, more reliable websites with better SEO ranking capabilities and happier end users overall.",
    "Building your new website on the Jamstack will ensure your site's lightning-fast, and our clients have seen decreasing bounce rates by 20 percent, 30 percent, or more compared to their previous website built on a less modern platform.",
    "The best thing about the Jamstack is that you’re not limited to your tools, there is a CMS or framework for any team, big or small - you name it. Read about the best parts below…"
]
const icons = [
    {
        name: "performance",
        title: "Peak performance",
        text: "Simply the best way to improve Core Web Vitals, Lighthouse scores, and website conversions? You guessed it—by switching over to Jamstack."
    },
    {
        name: "tool",
        title: "Keep the tools you love",
        text: "Your team is used to writing and modelling content in a specific CMS. With the Jamstack, you don’t need to take that away."
    },
    {
        name: "shield",
        title: "Security",
        text: "The Jamstack removes multiple moving parts and systems from the hosting infrastructure resulting in fewer servers and systems to harden against attack."
    },
    {
        name: "chart",
        title: "SEO Advantage",
        text: "The core focus of Jamstack is its out of the box SEO friendly approach and its undeniable and so far, unrivalled performance benchmarks."
    },
    {
        name: "computer",
        title: "Maintainability",
        text: 'When hosting complexity is reduced, so are maintenance tasks. A pre-generated site, being served directly from a simple host or directly from a CDN does not need a team of experts to "keep the lights on".'
    }
]
const cta1 = {
    title: "Stay ahead of the game with the latest tools and technology",
    description:
        "Maximise your business potential with a truly optimised and performant website built with the latest, innovative technologies. Reach out to us today for a consultation"
}
const cta2 = {
    title: "Ready to launch your project into the future of the web?"
}

const Jamstack = ({ location }) => {
    const context = {
        pageName: "Service | Jamstack Development",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroJamstack
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <ServiceIconBlock
                title="How the Jamstack can benefit your project"
                icons={icons}
            />
            <TextImageJamStack
                title={cta1.title}
                description={cta1.description}
            >
                <StaticImage
                    alt="jamstack"
                    src="../../components/ServiceSinglePage/images/jamstack-text-image.svg"
                />
                <a href="http://" target="_blank" rel="noopener noreferrer"></a>
            </TextImageJamStack>
            <div className="pt-20 md:pt-0">
                <ServiceCTA title={cta2.title} />
            </div>
            <ThreeColumnContent localisationData={localisationData.jamstack}/>

            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.jamstackFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    )
}

export default Jamstack
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: [
                        {
                            "@type": "Question",
                            name: "Why is Saigon Digital your first choice as a Jamstack development agency?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Saigon Digital stands out for our expertise in Jamstack, offering high-performance, secure, and scalable web solutions. With a proven track record, we deliver fast, SEO-friendly websites that enhance user engagement. Our comprehensive services and client-focused approach make us a reliable partner for businesses looking to excel online."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What does a Jamstack development service involve?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "A Jamstack development service focuses on building fast, lightweight web applications using JavaScript, APIs, and markup (HTML/CSS). This approach prioritises performance, making it a popular choice for developers and businesses alike."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Why is it named Jamstack?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Jamstack is a modern architecture for website development and applications. The acronym 'JAM' in Jamstack represents JavaScript, APIs, and Markup. Unlike traditional platforms like WordPress or Drupal, Jamstack does not rely on a database, which simplifies and speeds up the development process."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What benefits does Jamstack architecture offer?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Jamstack architecture is designed to enhance website speed, security, and scalability. It leverages tools and workflows familiar to developers, maximising productivity and ensuring a robust, future-proof website development process."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How does a Jamstack development agency enhance website performance?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "A Jamstack development agency improves your website's performance by using static site generation and Content Delivery Networks (CDNs). This means your pages are pre-built and served from the closest server, leading to faster load times and a more responsive user experience."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What SEO advantages come with using Jamstack development services?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Jamstack development services offer substantial SEO advantages by delivering faster load times, which positively impact Core Web Vitals. This results in better user engagement and lower bounce rates, key factors in search engine rankings. Additionally, Jamstack’s clean code structure, featuring semantic HTML, enhances how search engines crawl and index your site, boosting your visibility."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What types of projects are ideal for Jamstack development?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Jamstack development is particularly well-suited for projects like e-commerce sites, blogs, and documentation websites. Its high performance, security, and scalability make it an excellent choice. E-commerce platforms benefit from dynamic content delivery, blogs enjoy fast load times and easy updates, and documentation sites can efficiently manage large volumes of content through version control."
                            }
                        }
                    ]
                })}
            </script>
        </Helmet>
        <SEO
            title={
                "JamStack Development | Jamstack Development Agency | Saigon Digital"
            }
            description={
                "Building your new website on the Jamstack will ensure your site's lightning-fast, and our clients have seen decreasing bounce rates by 20 percent, 30 percent"
            }
        />
    </>
)
