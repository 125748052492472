import Piece1 from '@images/jamstack-piece1.svg'
import Piece2 from '@images/jamstack-piece2.svg'
import Piece3 from '@images/jamstack-piece3.svg'
import Vector from '@images/jamstack-vector.svg'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import React, { useEffect, useRef } from 'react'
const JamstackIcon = () => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    useEffect(() => {
        const element = ref.current
        gsap.fromTo(
            element.querySelector('.piece1'),
            {
                top: '20px',
                right: '68px',
                rotate: '0',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 237px)',
                right: '174px',
                rotate: '360',

                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
    }, [])
    useEffect(() => {
        const element = ref.current
        gsap.fromTo(
            element.querySelector('.piece2'),
            {
                top: '20px',
                x: 0,
                left: '60px',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 139px)',
                left: 'calc(100% - 376px)',
                rotate: '-15',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
    }, [])
    useEffect(() => {
        const element = ref.current
        gsap.fromTo(
            element.querySelector('.piece3'),
            {
                top: '33%',
                right: '68px',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 125px)',
                right: '175px',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
    }, [])
    return (
        <div
            ref={ref}
            className="hidden xl:block relative md:col-[1_/_2] md:row-[2_/_4]"
        >
            <div className="absolute top-[10%] wrap-pice"></div>
            <div className="piece1 absolute w-[100px] z-[2]">
                <img alt="jamstack-piece" src={Piece1} />
            </div>
            <div className="piece2 absolute w-[100px] z-[2]">
                <img alt="jamstack-piece" src={Piece2} />
            </div>
            <div className="piece3 absolute w-[100px] z-[2]">
                <img alt="jamstack-piece" src={Piece3} />
            </div>
            <div className="absolute bottom-0 right-[150px] w-[260px] z-[1]">
                <img alt="jamstack-piece" src={Vector} />
            </div>
        </div>
    )
}

export default JamstackIcon
