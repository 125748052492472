import React from 'react'
import JamstackIcon from './JamstackIcon'
import Hero from './../Hero'

const HeroJamstack = ({
    heroTextTitle,
    heroTextSmallText,
    ctaTitle,
    heroData
}) => {
    return (
        <Hero
            heroTextTitle={heroTextTitle}
            heroTextSmallText={heroTextSmallText}
            ctaTitle={ctaTitle}
            heroData={heroData}
        >
            <JamstackIcon />
        </Hero>
    )
}

export default HeroJamstack
